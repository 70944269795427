import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  showPassowrd: boolean;
  showConfirmPassword: boolean;
  validate: boolean;
  showModal: boolean;
  // Customizable Area Start
  termsAndConditionPageContentData: any;
  getTermsAndConditionPageContentDataId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SignUpPageController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      showPassowrd: false,
      showConfirmPassword: false,
      validate: false,
      showModal: false,
      termsAndConditionPageContentData: {},
      getTermsAndConditionPageContentDataId: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({
          termsAndConditionPageContentData: responseJson?.data[0] || {},
        });
      }
    }
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  setShowPassword = (show: boolean) => {
    this.setState({ showPassowrd: show });
  };

  setModalShow = (showModal: boolean) => {
    this.setState({ showModal });
  };

  setShowConfirmPassword = (show: boolean) => {
    this.setState({ showConfirmPassword: show });
  };

  goToLogIn = () => {
    this.props.navigation.navigate("LogInPage");
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    this.getTermsAndConditionPageContentData();
  }

  getTermsAndConditionPageContentData = async () => {
    // const token = await storage.get("loginToken");
    // const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      getTermsAndConditionPageContentDataId: requestMessage.messageId,
    });
    //  this.getTermsAndConditionPageContentDataId =  requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_terms_and_conditions/terms_and_conditions`
    );

    // getLoginRecentJobsMsg.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
