import React from "react";
// Customizable Area Start
import { Typography, Button, Box, Grid, Container, Link, List } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  banner,
  machine,
  meterService,
  costomizedService,
  inspectionService,
  clintService,
  servicesBanner,
  otherPagesMobileUiBanner
} from "./assets";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { configJSON } from "./LandingPageController";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

// Customizable Area Start

// Customizable Area End
export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  trimContentTextCount = 410
  baseURL = require("../../../framework/src/config.js").baseURL;
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box className="landing-page">
        <Box
          sx={{
            ...webStyle.bannerContent,
          }}
          className="bannerContent"
        >
          <Box
            component="img"
            src={banner}
            sx={{...webStyle.bannerImg}}
            className="bannerImage"
          /> 
          <Box component={"img"} sx={{...webStyle.otherPagesMobileUiBannerStyle}} src={otherPagesMobileUiBanner} alt="Banner_Img" />
          <Box className="centerBox" sx={{...webStyle.centerBox}}>
            <Typography variant="h3" sx={{ ...webStyle.bannerTitle }} data-test-id="hero_text">
              {this.state.homePageContentData?.hero_text}
            </Typography>
            <Link href={this.state.homePageContentData?.button1_link} sx={{ ...webStyle.linkStyle}}>
              <Button variant="outlined" sx={{ ...webStyle.phoneButton }}>
                <LocalPhoneIcon /> {this.state.homePageContentData?.button1_text}
              </Button>
            </Link>
            <Link href={this.state.homePageContentData?.button2_link} sx={{ ...webStyle.linkStyle}}>
            <Button variant="contained" sx={{ ...webStyle.requestButton }}>
              {this.state.homePageContentData?.button2_text}
            </Button>
            </Link>
          </Box>
        </Box>

        <Box
          sx={{
            ...webStyle.clientTestimonialsImageContainer
          }}
          id="services"
          ref={this.state.scrollContainerRef}
          style={{backgroundPosition: this.state.backgroundPosition}}
          className="services"
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"    
          >
            <Grid
              className="services-box"
              item
              sx={{ ...webStyle.serviceList }}
            >
              <Box
                sx={{
                  ...webStyle.clinetTestimonialsBoxes,
                  ...webStyle.overlayFirst,
                }}
              >
                <Box
                  sx={{
                    ...webStyle.clientTestimonialsImage,
                    background: `url(${this.baseURL}${this.state.homePageContentData?.signpost1_image_url}) no-repeat center`,
                  }}
                  
                >
                  <Box
                    className="overlayFirst"
                    sx={{ ...webStyle.boxOverlay }}
                  />
                  <Typography
                    className="overlayFirstTitle"
                    sx={{
                      ...webStyle.clientTestimonialsImageTitle,
                    }}
                  >
                    {this.state.homePageContentData?.signpost1_title}
                  </Typography>
                </Box>
                <Typography align="center" sx={{ ...webStyle.textStyleOne }}>
                  {this.state.homePageContentData?.signpost1_text}
                </Typography>
                <Link href={this.state.homePageContentData?.signpost1_hyperlink} sx={{ ...webStyle.linkStyle}}>
                <Typography
                  className="overlayFirstText"
                  align="center"
                  sx={{ ...webStyle.textStyleTwo }}
                >
                  {this.state.homePageContentData?.signpost1_link_text}
                  <ArrowCircleRightIcon />
                </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid item sx={{ ...webStyle.serviceList }}>
              <Box
                sx={{
                  ...webStyle.clinetTestimonialsBoxes,
                  ...webStyle.overlaySecond,
                }}
              >
                <Box
                  sx={{
                    ...webStyle.clientTestimonialsImageSecond,
                    background: `url(${this.baseURL}${this.state.homePageContentData?.signpost2_image_url}) no-repeat center`,

                  }}
                >
                  <Box
                    className="overlaySecond"
                    sx={{ ...webStyle.boxOverlay }}
                  />

                  <Typography
                    className="overlaySecondTitle"
                    sx={{
                      ...webStyle.clientTestimonialsImageTitle,
                    }}
                  >
                    {this.state.homePageContentData?.signpost2_title}
                  </Typography>
                </Box>
                <Typography
                  align="center"
                  sx={{
                    ...webStyle.textStyleOne,
                    transition: "opacity 0.6s ease-in-out",
                    color: "#FFFFFF",
                  }}
                >
                  {this.state.homePageContentData?.signpost2_text}
                </Typography>
                <Link href={this.state.homePageContentData?.signpost2_hyperlink} sx={{ ...webStyle.linkStyle}}>
                <Typography
                  className="overlaySecondText"
                  align="center"
                  sx={{ ...webStyle.textStyleTwo }}
                >
                  {this.state.homePageContentData?.signpost2_link_text}
                  <ArrowCircleRightIcon />
                </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid item sx={{ ...webStyle.serviceList }}>
              <Box
                sx={{
                  ...webStyle.clinetTestimonialsBoxes,
                  ...webStyle.overlayThird,
                }}
              >
                <Box
                  sx={{
                    ...webStyle.clientTestimonialsImageThird,
                    background: `url('${this.baseURL}${this.state.homePageContentData?.signpost3_image_url}') no-repeat center`,
                  }}
                >
                  <Box
                    className="overlayThird"
                    sx={{ ...webStyle.boxOverlay }}
                  />
                  <Typography
                    className="overlayThirdTitle"
                    sx={{
                      ...webStyle.clientTestimonialsImageTitle,
                    }}
                  >
                    {this.state.homePageContentData?.signpost3_title}
                  </Typography>
                </Box>
                <Typography align="center" sx={{ ...webStyle.textStyleOne }}>
                  {this.state.homePageContentData?.signpost3_text}
                </Typography>
                <Link href={this.state.homePageContentData?.signpost3_hyperlink} sx={{ ...webStyle.linkStyle}}>
                <Typography
                  className="overlayThirdText"
                  align="center"
                  sx={{ ...webStyle.textStyleTwo }}
                >
                  {this.state.homePageContentData?.signpost3_link_text} 
                  <ArrowCircleRightIcon />
                </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid item sx={{ ...webStyle.serviceList }}>
              <Box
                sx={{
                  ...webStyle.clinetTestimonialsBoxes,
                  ...webStyle.overlayFourth,
                }}
              >
                <Box
                  sx={{
                    ...webStyle.clientTestimonialsImageFour,
                    background: `url(${this.baseURL}${this.state.homePageContentData?.signpost4_image_url}) no-repeat center`,

                  }}
                >
                  <Box
                    className="overlayFourth"
                    sx={{ ...webStyle.boxOverlay }}
                  />
                  <Typography
                    className="overlayFourthTitle"
                    sx={{
                      ...webStyle.clientTestimonialsImageTitle,
                    }}
                  >
                    {this.state.homePageContentData?.signpost4_title}
                  </Typography>
                </Box>
                <Typography align="center" sx={{ ...webStyle.textStyleOne }}>
                  {this.state.homePageContentData?.signpost4_text}
                </Typography>
                <Link href={this.state.homePageContentData?.signpost4_hyperlink} sx={{ ...webStyle.linkStyle}}>
                <Typography
                  className="overlayFourthText"
                  align="center"
                  sx={{ ...webStyle.textStyleTwo }}
                >
                  {this.state.homePageContentData?.signpost4_link_text} 
                  <ArrowCircleRightIcon />
                </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ ...webStyle.servicesContainer }} className="meter-services">
          <Box>
            <Typography variant="h4" sx={{ ...webStyle.servicesTitle }}>
              {this.state.homePageContentData?.content_title}
            </Typography>
            <Box component="span"
              dangerouslySetInnerHTML={{ __html: this.state.homePageContentData?.content1_text }}>
            </Box>
            <Box sx={{ ...webStyle.machineImageContainer }}>
              <Box component="img"
                sx={{ ...webStyle.machineImage ,   
                  color: this.state.isImageLoadFailed ? "grey" : "",
                }}
                src={this.baseURL+this.state.homePageContentData?.content_image_url}
                alt="!Error: Image failed to load."
                data-test-id="isImageLoadFailed"
                onError={() => this.setState({
                  isImageLoadFailed: true
                })}
              />
            </Box>
              <Box component="div" sx={webStyle.content2_text} dangerouslySetInnerHTML={{__html: this.state.homePageContentData?.content2_text}}></Box>
              <Box component="div" dangerouslySetInnerHTML={{__html: this.state.homePageContentData?.content3_text}}></Box>
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  bannerImg: {
    width: "100%",
    // height: "100vh",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: "none",
    height: "initial",
    display: "block",
    "@media only screen and (min-device-width: 499px) and (max-device-width: 767px)": {
      height: "auto",
    },
    "@media (max-width: 500px)": {
      display: "none"
    }
  },
  otherPagesMobileUiBannerStyle: {
    display: "none",
    "@media (max-width: 500px)": {
      width: "100%",
      // height: "100vh",
      position: "relative",
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxWidth: "none",
      height: "initial",
      display: "block",
    }
  },
  centerBox: {
    width: "85%",
    maxWidth: "610px",
    position: "absolute",
    margin: "0 auto",
    left: 0,
    right: 0,
    bottom: "180px",
    padding: "46px 30px",
    textAlign: "center",
    backgroundColor: "rgba(9, 21, 33, 0.75)",
    "@media only screen and (min-device-width: 955px) and (max-device-width: 1300px)": {
      bottom: "40px",
    },
    "@media only screen and (min-device-width: 768px) and (max-device-width: 954px)": {
      bottom: "30px",
    },
    "@media only screen and (min-device-width: 499px) and (max-device-width: 767px)": {
      bottom: "20px",
    },
    "@media only screen and (min-device-width: 342px) and (max-device-width: 500px)": {
      bottom: "60px",
      p:"20px"
    },
    "@media (max-width: 341px)": {
      bottom: "10px",
      p:"18px"
    }
  },
  bannerContent: {
    width: "100%",
    position: "relative",
  },
  bannerTitle: {
    fontWeight: "800",
    fontSize: "51px",
    lineHeight: "4.689rem",
    alignContent: "center",
    letterSpacing: "-0.6px",
    color: "white",
    mb: "12px",
    "@media (max-width: 500px)": {
      fontSize: "20px",
      lineHeight: "22px",
    },
    "@media only screen and (min-device-width: 499px) and (max-device-width: 767px)": {
      fontSize: "41px",
    },
    "@media (maxWidth: 767px)": {
      fontSize: "23px",
      mt: "0",
    },
    fontFamily: "'Nunito Sans', sans-serif",
  },
  phoneButton: {
    mt: 2,
    color: "white",
    border: "0.125rem solid #FFFFFF",
    gap: "14px",
    fontSize: "18px",
    fontWeight: "800",
    p: "0px 60px",
    height: "95px",
    fontFamily: "'Nunito Sans', sans-serif",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "@media (max-width: 341px)": {
      width: "200px",
      height: "72px",
      p:"15px",
    },
    "@media only screen and (min-device-width: 342px) and (max-device-width: 500px)": {
      width:"300px",
      height: "80px",
      p: "20px",
      fontSize: "20px",
      lineHeight: "32px",
    },
    "@media only screen and (min-device-width: 499px) and (max-device-width: 767px)": {
      p: "17px"
    },
    "@media (maxWidth: 767px)": {
      margin: "0 0 10px 0",
    },
  },
  requestButton: {
    mt: 2,
    ml: 2,
    color: "white",
    background: "#3FB1CE",
    fontSize: "18px",
    fontWeight: "800",
    p: "0px 60px",
    height: "95px",
    fontFamily: "'Nunito Sans', sans-serif",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
    "@media (max-width: 341px)": {
      width: "200px",
      height: "72px",
      p:"15px",
      fontSize:"16px",
      ml:"0"
    },
    "@media only screen and (min-device-width: 342px) and (max-device-width: 500px)": {
      width:"300px",
      height: "80px",
      p: "20px",
      fontSize: "20px",
      lineHeight: "32px",
      ml:"0"
    },
    "@media only screen and (min-device-width: 499px) and (max-device-width: 767px)": {
      p: "17px"
    },
  },
  serviceList: {
    "@media (maxWidth: 767px)": {
      pl: "0",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (minWidth: 900px)": {
      margin: "0 20px",
      flexBasis: "22%",
    },
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)":
      {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      },
  },
  clientTestimonialsImageContainer: {
    display: "block",
    position: "relative",
    backgroundImage:`url(${servicesBanner})`,
    backgroundColor: "#1d8dbc",
    backgroundRepeat: "no-repeat",
    padding: "75px 0",
    "@media (max-width: 767px)": {
      backgroundSize: "cover",
      padding: "75px 99px",
    },
  },
  clinetTestimonialsBoxes: {
    width: "15.75rem",
    height: "25.162rem",
    margin: "1rem",
    gap: "1rem",
  },
  clientTestimonialsImage: {
    width: "15.75rem",
    height: "15.313rem",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
  },
  clientTestimonialsImageSecond: {
    width: "15.75rem",
    height: "15.313rem",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
  },
  clientTestimonialsImageThird: {
    width: "15.75rem",
    height: "15.313rem",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
  },
  clientTestimonialsImageFour: {
    width: "15.75rem",
    height: "15.313rem",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
  },
  clientTestimonialsImageTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "23px",
    fontWeight: "900",
    top: 5,
    left: 5,
    lineHeight: "22px",
    margin: "0px",
    position: "absolute",
    width: "100%",
    height: "100%",
    color: "#233865",
  },
  textStyleOne: {
    fontFamily: "'Nunito Sans', sans-serif",
    marginTop: "1rem",
    fontSize: "16px",
    lineHeight:"22.4px",
    fontWeight: 500,
    letterSpacing: "0",
    color: "#FFFFFF",
    whiteSpace: "preserve-spaces",
  },
  textStyleTwo: {
    fontFamily: "'Nunito Sans', sans-serif",
    lineHeight:"35.6px",
    fontSize: "23px",
    marginTop: "1rem",
    color: "#FFFFFF",
    display: "flex",
    fontWeight: "900",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  servicesContainer: {
    padding: "80px 90px",
    backgroundColor: "#E5F4FB",
    "@media (max-width: 767px)": {
      p: "40px 16px",
    }
  },
  servicesTitle: {
    fontWeight: 700,
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: "1rem",
    textAlign: "start",
    lineHeight: "44px",
    color: "#12629A",
    "@media (max-Width: 767px)": {
      fontSize: "23px",
      fontWeight: 900,
      textAlign: "start",
      lineHeight: "25.3px",
    },
  },
  servicesDescription: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: "2rem",
    textAlign: "start",
    lineHeight: "29.12px",
    "@media (maxWidth: 767px)": {
      textAlign: "left",
      color: "#000",
    },
  },
  commitmentTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 700,
    marginBottom: "1rem",
    textAlign: "start",
    color: "#E5614B",
    "@media (max-width: 767px)": {
      fontSize: "22px",
      textAlign: "left",
      lineHeight: "28.6px",
    },
  },
  commitmentDescription: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "18px",
    fontWeight:"400",
    marginBottom: "2rem",
    textAlign: "start",
    lineHeight: 1.5,
    "@media (max-width: 767px)": {
      textAlign: "left",
      color: "#000",
    },
  },
  machineImageContainer: {
    textAlign: "center",
    margin: "2rem 0",
    height:"480px",
    "@media (max-width: 767px)": {
      height:"364px",
    },
  },
  machineImage: {
    maxWidth: "100%",
    height: "auto",
  },
  missionTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: "1rem",
    color: "#E5614B",
    fontWeight: "bold",
    "@media (max-width: 767px)": {
      fontSize:"22px",
      lineHeight:"28.6px",
      fontWeight:"700"
    }
  },
  missionSubtitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    color: "#3FB1CE",
    marginBottom: "1rem",
    fontSize: "1.5rem",
    fontWeight:"600",
    "@media (max-width: 767px)": {
      fontSize:"21px",
      lineHeight:"28.6px",
      fontWeight:"600"
    }
  },
  content2_text: {
    "> *:first-child": {
      fontFamily: "'Nunito Sans', sans-serif",
      marginBottom: "1rem",
      color: "#E5614B",
      fontWeight: "500",
      textAlign: "center",
      fontSize: "26px",
      "@media (max-width: 767px)": {
        fontSize: "22px",
        lineHeight: "28.6px",
        fontWeight: "500"
      }
    },
    "> p:nth-of-type(2)": {
      fontFamily: "'Nunito Sans', sans-serif",
      color: "#3FB1CE",
      marginBottom: "1rem",
      fontSize: "1.5rem",
      fontWeight: "600",
      textAlign: "center",
      "@media (max-width: 767px)": {
        fontSize: "21px",
        lineHeight: "28.6px",
        fontWeight: "600"
      }
    },
  },
  missionDescription: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "18px",
    marginBottom: "2rem",
    textAlign: "left",
    lineHeight: 1.5,
    fontWeight:"400"
  },
  customersTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 700,
    marginBottom: "10px",
    textAlign: "left",
    color: "#E5614B",
  },
  customersListBox:{
    ml: "20px",
    "@media (max-width: 767px)": {
      p: "18px 32px 0px 32px",
      gap:"30px",
      ml: "0px"
    }
  },
  customerListItem: {
    display:"list-item",
    listStyle:"disc",
    letterSpacing:"0",
    fontWeight:"500",
    fontSize: "16px",
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: "0.5rem",
    color: "#000000",
    "@media (max-width: 767px)": {
      listStyle: "none",
      fontSize: "18px",
      lineHeight:"29.12px"
    }
  },
  contactLink: {
    fontSize: "18px",
    fontFamily: "'Nunito Sans', sans-serif",
    textAlign: "start",
    marginTop: "2rem",
    display: "block",
  },
  boxOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    opacity: 0,
  },
  overlayFirst: {
    "&:hover .overlayFirst": {
      opacity: 1,
    },
    "&:hover .overlayFirstText": {
      color: "#233865",
    },
    "&:hover .overlayFirstTitle": {
      color: "#FFFFFF",
      opacity: 1,
      fontWeight: "bold",
    },
  },
  overlaySecond: {
    "&:hover .overlaySecond": {
      opacity: 1,
    },
    "&:hover .overlaySecondText": {
      color: "#233865",
    },
    "&:hover .overlaySecondTitle": {
      color: "#FFFFFF",
      opacity: 1,
      fontWeight: "bold",
    },
  },
  overlayThird: {
    "&:hover .overlayThird": {
      opacity: 1,
    },
    "&:hover .overlayThirdText": {
      color: "#233865",
    },
    "&:hover .overlayThirdTitle": {
      color: "#FFFFFF",
      opacity: 1,
      fontWeight: "bold",
    },
  },
  overlayFourth: {
    "&:hover .overlayFourth": {
      opacity: 1,
    },
    "&:hover .overlayFourthText": {
      color: "#233865",
    },
    "&:hover .overlayFourthTitle": {
      color: "#FFFFFF",
      opacity: 1,
      fontWeight: "bold",
    },
  },
  linkStyle: {
    textDecoration: "none",
  }
};
// Customizable Area End
