import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from "@mui/material"

export const configJSON = require("./config");
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { otherPagesMobileUiBanner } from "../../landingpage/src/assets";
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
                <Box component={"img"} className='otherPagesMobileUiBannerStyle' 
                  src={otherPagesMobileUiBanner} alt="Banner_Img" />
        
        <ThemeProvider theme={theme}>
          <Box sx={webStyle.contactUsWrapper}>
              {/* Customizable Area Start */}
              <Box sx={webStyle.headerTexts}>
                <Typography sx={webStyle.mainHeader}>
                  {configJSON.headerText}
                </Typography>
                <Typography sx={webStyle.subHeader}>
                  <Typography component="span" sx={webStyle.servicesLink}>
                  <Link href="/Services" sx={{color:"rgb(18, 98, 154)"}}>Services</Link>
                  </Typography>
                  {configJSON.subHeaderText}
                </Typography>
                <Typography sx={webStyle.subHeader}>
                  {configJSON.detailHeaderText}
                </Typography>
              </Box>

              <Box sx={webStyle.contactDetailsWrapper}>
                <Box sx={webStyle.contactDetailsBox}>
                  <Typography sx={webStyle.contactDetailText}>
                    <Typography component="span" sx={webStyle.boldText}>{configJSON.detailPhoneLabel}:</Typography> {configJSON.detailPhoneValue}
                  </Typography>
                  <Typography sx={webStyle.contactDetailText}>
                    <Typography component="span" sx={webStyle.boldText}>{configJSON.detailFaxLabel}:</Typography> {configJSON.detailFaxValue}
                  </Typography>
                  <Typography sx={webStyle.contactDetailTextWithIcon}>
                    <Typography component="span" sx={webStyle.boldText}>{configJSON.detailEmailLabel}: </Typography><Typography component="span" sx={webStyle.emailLink}><MailOutlineIcon sx={{ marginLeft: "4px" }} />{configJSON.detailEmailValue}</Typography>
                  </Typography>
                  <Typography sx={webStyle.contactLocationDetailText}>
                    <Typography component="span" sx={webStyle.boldText}>{configJSON.detailLocationLabel}:</Typography><br /> {configJSON.detailLocationValue}
                  </Typography>

                  <Box sx={webStyle.contactUsMapIframe}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.3744746350762!2d-73.89555668459721!3d40.64367497933931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25ce0a1df3b25%3A0x418553ef185e7922!2s1056+E+103rd+St%2C+Brooklyn%2C+NY+11236%2C+USA!5e0!3m2!1sen!2sca!4v1555604867499!5m2!1sen!2sca" width="100%" height="415" style={{ border: 0 }}></iframe>
                  </Box>
                </Box>


                <Box sx={webStyle.formBox}>
                  <Box sx={webStyle.formContent}>
                    <Typography sx={webStyle.requiredFieldsText}>
                      Fields marked with an <Typography component="span" sx={{ color: "red" }}>*</Typography> are required
                    </Typography>
                    <Typography sx={webStyle.formLabel}>
                      {configJSON.formNameLabel} <Typography component="span" sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <Box component={"input"} type="text" sx={webStyle.formInput} />

                    <Box sx={{ display: "flex", gap: "8px", width: "100%", justifyContent: "space-around" }}>
                      <Box sx={{ width: "100%" }}>
                        <Typography sx={webStyle.formLabel}>
                          {configJSON.formEmailLabel} <Typography component="span" sx={{ color: "red" }}>*</Typography>
                        </Typography>
                        <Box component={"input"} type="text" sx={webStyle.formInput} />
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <Typography sx={webStyle.formLabel}>
                          {configJSON.formPhoneLabel} <Typography component="span" sx={{ color: "red" }}>*</Typography>
                        </Typography>
                        <Box component={"input"} type="text" sx={webStyle.formInput} />
                      </Box>
                    </Box>

                    <Typography sx={webStyle.formLabel}>
                      {configJSON.formServicesNeededLabel}
                    </Typography>
                    <Box component={"input"} type="text" sx={webStyle.formInput} />

                    <Typography sx={webStyle.formLabel}>
                      {configJSON.formCommentsLabel} <Typography component="span" sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <Box component={"textarea"} rows={12} sx={webStyle.textarea} />
                    <Button sx={webStyle.formSubmitButton}>{configJSON.formButtonText}</Button>
                  </Box>
                </Box>
              </Box>
              {/* Customizable End Start */}
            
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start
const webStyle = {
  contactUsWrapper: {
    backgroundColor: "#E5F4FB",
    p: "80px 80px",
    "@media (max-width: 810px)": {
      p: "40px 16px",
    }
  },
  headerTexts: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  mainHeader: {
    letterSpacing: "-0.5px",
    fontSize: "30px",
    fontWeight: "800",
    color: "#12629A",
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width: 810px)": {
      fontSize: "23px",
      fontWeight: "900",
      lineHeight: "25.3px",
    }
  },
  subHeader: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24.55px",
    fontFamily: "'Nunito Sans', sans-serif",
  },
  servicesLink: {
    color: "#12629A",
    marginRight: "5px",
    textDecoration: "underline",
  },
  contactDetailsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
    paddingTop: "20px",
    width: "100%",
    "@media (max-width: 780px)": {
      flexWrap: "wrap",
      justifyContent: "center"
    }
  },
  contactDetailsBox: {
    width: "48%",
    "@media (max-width: 780px)": {
      width: "100%",
      height: "fit-content"
    }
  },
  contactDetailText: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "29.12px",
    fontFamily: "'Nunito Sans', sans-serif",
  },
  contactLocationDetailText: {
    padding: "30px 0",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "29.12px",
    fontFamily: "'Nunito Sans', sans-serif",
  },
  contactDetailTextWithIcon: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "29.12px",
    fontFamily: "'Nunito Sans', sans-serif",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    maxWidth: "100%",
  },
  emailLink: {
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    textDecoration: "underline",
    color: "#12629A",
  },
  boldText: {
    fontWeight: "700",
  },
  contactUsMapIframe: {
    width: "100%",
    "@media (max-width: 780px)": {
      height: "50%"
    }
  },
  formBox: {
    width: "48%",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    "@media (max-width: 780px)": {
      width: "100%"
    }
  },
  formContent: {
    padding: "20px",
  },
  requiredFieldsText: {
    fontSize: "13.5px",
    fontWeight: "400",
    lineHeight: "21.84px",
    fontFamily: "'Nunito Sans', sans-serif",
    textAlign: "end",
  },
  formLabel: {
    fontWeight: "700",
  },
  formInput: {
    width: "100%",
    padding: "10px",
    marginBottom: "16px",
    borderRadius: "2.7px",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    marginTop: "6px",
    boxShadow: "inset 1px 1px 4px #DFDFDF",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    marginBottom: "16px",
    borderRadius: "2.7px",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    marginTop: "6px",
    resize: "none",
    boxShadow: "inset 1px 1px 4px #DFDFDF",
  },
  formSubmitButton: {
    width: "148px",
    height: "39px",
    padding: "9px 45px",
    borderRadius: "2.7px",
    background: "rgba(18, 98, 154, 1)",
    color: "white",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "18px",
    fontWeight: "400",
    textTransform: "capitalize",
    lineHeight: "20.7px",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  }
};
// Customizable Area End