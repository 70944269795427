import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip } from "@mui/material";
import * as Yup from "yup";

import { Formik } from "formik";
import { banner, passwordHiddenIcon, navLogo, errorIcon } from "./assets";
// Customizable Area End

import LogInPageController, { Props, configJSON } from "./LogInPageController";
// Customizable Area Start

// Customizable Area End
export default class LogInPage extends LogInPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.signUpContainer}>
        <Box sx={webStyle.leftBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.signUpFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>
                {configJSON.logInText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.welcomeBackText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
              }}
              data-test-id="SignUpForm"
            >
              {({ errors, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"email"}>
                        {configJSON.emailText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="email"
                        type="email"
                        placeholder={configJSON.emailPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.email && (
                        <Tooltip
                          open={Boolean(errors.email)}
                          title={errors.email}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"password"}>
                        {configJSON.passwordText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="password"
                        type={this.state.showPassowrd ? "text" : "password"}
                        placeholder={configJSON.passwordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.password ? (
                        <Tooltip
                          open={Boolean(errors.password)}
                          title={errors.password}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      ) : (
                        <img
                          src={passwordHiddenIcon}
                          alt=""
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowPassword(!this.state.showPassowrd)
                          }
                        />
                      )}

                      <button 
                        type="button"
                        style={webStyle.forgotPaswordButton}
                        onClick={() => { this.goToForgotPasswordPage(); }}
                      >
                        {configJSON.forgotPasswordText}
                      </button>
                    </Box>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="submit"
                      onClick={() => {
                        this.setValidate(true);
                        if (Object.keys(errors).length == 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      {configJSON.logInText}
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box sx={webStyle.signUpRedirection}>
              <Typography style={webStyle.signUpRedirectionLabel}>
                {configJSON.donthaveAnAccountText}
              </Typography>
              <button 
                type="button"
                style={webStyle.signUpRedirectionButton}
                onClick={() => { this.goToSignUpPage(); }}
              >
                {configJSON.signUpText}
              </button>
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.rightBlock}>
          <Box sx={webStyle.rightBlockBackground} />
          {/* Bkock right */}
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  signUpContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  rightBlock: {
    flex: 1,
    display: "flex",
  },
  rightBlockBackground: {
    flex: 1,
    display: "flex",
    margin: "20px",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    borderRadius: "24px",
    height: "880px",
  },
  leftBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "40px auto",
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: "10px",
  },
  inputLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  passwordHelp: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  checkboxLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  checkboxElemnt: {
    height: "26px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #64748B",
  },
  submitButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
  },
  signUpRedirection: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "20px auto",
    width: "max-content",
  },
  signUpRedirectionLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: "8px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  signUpRedirectionButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
  },
  forgotPaswordButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    textAlign: "right" as const,
    marginTop: "20px",
    marginBottom: "40px",
  },
};
// Customizable Area End
